.default {
  width:fit-content;
  color: white;
  text-align: center;
  justify-self: center;
}

.dropdownIcon {
  padding: 5px;
}

